/** @jsx jsx */
import { Grid, Box, jsx } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import DonationLayout from "../layout/DonationLayout";
import { eventCallbackTriggers } from "../templates/wppage";
import banner from "../../static/imgs/heartfulness-green-donation-banner.jpg";

const GeneralDonationSMSF = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Heartfulness Donation SMSF, USA";
    return `<gatsby_donation donationId = "${id}" btntext = "${titletext}" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "General Donation - SMSF, USA"/>`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("Heartfulness_Donation_SMSF,_USA", event),
    []
  );

  return (
    <>
      <DonationLayout
        seoData={{
          title: "Heartfulness Donations",
        }}
      >
        <Box
          sx={{
            paddingTop: "30px",
            alignItems: "center",
            minHeight: "620px",
            maxHeight: "620px",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${banner})`,
          }}
        >
          <Box sx={{ paddingBottom: "30px" }}>
            <h2
              sx={{
                color: "White",
                marginTop: "20px",
                fontWeight: "500",
                lineHeight: "1.2",
                fontSize: "55px",
                marginBottom: "1rem",
                textAlign: "center!important",
              }}
            >
              SMSF USA Donations
            </h2>
            <PageContent
              eventCallback={eventCallback}
              pageContent={pageContent(16, "Contribute Now")}
            />
          </Box>
        </Box>
        <Grid style={{ padding: "9.5px" }}>
          <Box>
            <div className="container" sx={{ padding: "20px 5px" }}>
              <div className="col-12">
                <h1
                  sx={{
                    fontSize: "1.5em",
                    color: "#2d3550",
                    fontWeight: "600",
                  }}
                >
                  General Donation to SMSF, USA
                </h1>
                <p sx={{ marginBottom: "1rem", textAlign: "justify" }}>
                  All donations to Sahaj Marg Spirituality Foundation are
                  voluntary. Donated funds are used to:
                </p>
                <ul>
                  <li sx={{ listStyleType: "disc", marginBottom: "0.5rem" }}>
                    Host seminars and gatherings in the state of Texas.
                  </li>
                  <li sx={{ listStyleType: "disc", marginBottom: "0.5rem" }}>
                    Support education, research and publications in the field of
                    spirituality.
                  </li>
                  <li sx={{ listStyleType: "disc", marginBottom: "0.5rem" }}>
                    Offer grants to similar non-profit organizations to promote
                    heartfulness meditation to countries around the globe.
                  </li>
                  <li sx={{ listStyleType: "disc", marginBottom: "0.5rem" }}>
                    Support Environment and Sustainability initiatives
                    (Heartfulness Green movement).
                  </li>
                </ul>
              </div>
              <div className="col-12 text-center">
                <PageContent
                  eventCallback={eventCallback}
                  pageContent={pageContent(16, "Contribute Now")}
                />
              </div>
            </div>
          </Box>
        </Grid>
      </DonationLayout>
    </>
  );
};

export default GeneralDonationSMSF;
